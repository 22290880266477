// src/providers/PixelProvider.js

import React, { createContext, useContext, useEffect } from 'react';
import { initializePixel, pixelUtils } from '../utils/pixelInit.js';
import { PIXEL_CONFIG } from '../pixel.config.js';

const PixelContext = createContext();

export const PixelProvider = ({ children }) => {
  useEffect(() => {
    const pixel = initializePixel();
    
    // Handle user consent (if needed)
    const handleConsent = (hasConsent) => {
      if (hasConsent) {
        pixelUtils.consent.grant();
        pixelUtils.dataProcessing.enable();
      } else {
        pixelUtils.consent.revoke();
        pixelUtils.dataProcessing.disable();
      }
    };

    // Check for existing consent
    const userConsent = localStorage.getItem('pixel_consent');
    if (userConsent) {
      handleConsent(JSON.parse(userConsent));
    }

    return () => {
      // Cleanup if needed
    };
  }, []);

  return (
    <PixelContext.Provider value={PIXEL_CONFIG}>
      {children}
    </PixelContext.Provider>
  );
};

export const usePixelContext = () => {
  const context = useContext(PixelContext);
  if (!context) {
    throw new Error('usePixelContext must be used within a PixelProvider');
  }
  return context;
};
