// src/config/pixel.config.js

export const PIXEL_CONFIG = {
    // Your Facebook Pixel ID
    FACEBOOK_PIXEL_ID: '3859371431056333',
    
    // Pixel initialization options
    OPTIONS: {
      autoConfig: true,
      debug: false,
      customHeaders: {
        'cache-control': 'no-cache',
      }
    },
    
    // Advanced matching parameters
    ADVANCED_MATCHING: {
      em: 'email@example.com' // Optional: for advanced matching
    }
  };
  