// src/index.js
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App.js';
import { AuthProvider } from './admin/AuthContext.jsx';
import { LeadProvider } from './admin/LeadsContext.jsx';
import { PixelProvider } from './providers/PixelProvider.js';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <BrowserRouter>
  <PixelProvider>
    <AuthProvider>
      <LeadProvider>
        <App />
      </LeadProvider>
    </AuthProvider>
    </PixelProvider>
  </BrowserRouter>
);
