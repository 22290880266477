import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { IoLocationOutline, IoTimeOutline } from "react-icons/io5";
import { MdOutlinePhone } from "react-icons/md";
import { FaInstagram, FaLinkedinIn } from "react-icons/fa6";
import { IoIosArrowForward } from "react-icons/io";
import { Link, useLocation } from "react-router-dom";
import { Helmet } from 'react-helmet';

// Brevo API Key
const API_KEY = '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';

const Contact = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const selectedType = params.get("typeplan");

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
    plan: selectedType || ""
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = 'Name is required';
    if (!formData.phone.trim()) newErrors.phone = 'Phone is required';
    if (!/^\d{10}$/.test(formData.phone.trim())) newErrors.phone = 'Invalid phone number';
    if (!formData.email.trim()) newErrors.email = 'Email is required';
    if (!/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = 'Invalid email address';
    if (!formData.message.trim()) newErrors.message = 'Message is required';
    return newErrors;
  };

  const sendEmail = async (emailData) => {
    try {
      const response = await fetch('https://api.sendinblue.com/v3/smtp/email', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'api-key': API_KEY
        },
        body: JSON.stringify(emailData)
      });

      if (!response.ok) {
        throw new Error('Failed to send email');
      }

      return await response.json();
    } catch (error) {
      console.error('Error sending email:', error);
      throw error;
    }
  };

  const sendEmails = async () => {
    try {
      // Get current time in Indian format
      const currentTime = new Date().toLocaleString('en-IN', { 
        timeZone: 'Asia/Kolkata',
        dateStyle: 'full',
        timeStyle: 'long'
      });
  
      // Email to owner with detailed user information
      const ownerEmailData = {
        sender: {
          name: "Lead Notification",
          email: "noreply@flyyourtech.com"
        },
        to: [{
          email: "flyyourtech@gmail.com",
          name: "Abhay Gupta"
        }],
        subject: `New Contact Request from ${formData.name}`,
        htmlContent: `
          <div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto; padding: 20px;">
            <h2 style="color: #333; border-bottom: 2px solid #913bfe; padding-bottom: 10px;">New Contact Request</h2>
            
            <div style="background: #f5f5f5; padding: 20px; border-radius: 5px; margin: 20px 0;">
              <h3 style="color: #913bfe; margin-top: 0;">Contact Information</h3>
              <p><strong>Name:</strong> ${formData.name}</p>
              <p><strong>Email:</strong> ${formData.email}</p>
              <p><strong>Phone:</strong> ${formData.phone}</p>
              <p><strong>Submission Time:</strong> ${currentTime}</p>
            </div>
  
            <div style="background: #f5f5f5; padding: 20px; border-radius: 5px; margin: 20px 0;">
              <h3 style="color: #913bfe; margin-top: 0;">Message</h3>
              <p style="white-space: pre-wrap;">${formData.message}</p>
            </div>
  
            <div style="background: #f5f5f5; padding: 20px; border-radius: 5px; margin: 20px 0;">
              <h3 style="color: #913bfe; margin-top: 0;">Additional Information</h3>
              <p><strong>User Agent:</strong> ${navigator.userAgent}</p>
              <p><strong>Page URL:</strong> ${window.location.href}</p>
              <p><strong>Device Type:</strong> ${/Mobile|Tablet|iPad/i.test(navigator.userAgent) ? 'Mobile Device' : 'Desktop'}</p>
            </div>
          </div>
        `
      };
  
      // Simple confirmation email to user
      const userEmailData = {
        sender: {
          name: "FlyYourTech",
          email: "noreply@flyyourtech.com"
        },
        to: [{
          email: formData.email,
          name: formData.name
        }],
        subject: "Thank you for reaching out to FlyYourTech",
        htmlContent: `
          <div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto; padding: 20px;">
            <h2 style="color: #333; text-align: center; border-bottom: 2px solid #913bfe; padding-bottom: 10px;">Thank You for Contacting Us!</h2>
            
            <p style="color: #444; font-size: 16px; margin-top: 20px;">Dear ${formData.name},</p>
            
            <p style="color: #444; line-height: 1.6;">Thank you for reaching out to FlyYourTech. We have received your message and our team will get back to you within 24 hours.</p>
            
            <div style="background: #913bfe; color: white; padding: 15px; border-radius: 5px; margin: 20px 0; text-align: center;">
              <p style="margin: 0; font-size: 16px;">Need immediate assistance?</p>
              <p style="margin: 5px 0 0 0; font-size: 16px;">Call us at: +917470391011</p>
            </div>
            
            <div style="margin-top: 30px; color: #666;">
              <p style="margin: 0;">Best regards,</p>
              <p style="margin: 5px 0 0 0;"><strong>FlyYourTech Team</strong></p>
              <div style="margin-top: 20px; border-top: 1px solid #eee; padding-top: 20px;">
                <p style="font-size: 12px; color: #888; text-align: center;">
                  Follow us on 
                  <a href="https://www.facebook.com/profile.php?id=61564327175573" style="color: #913bfe; text-decoration: none;">LinkedIn</a> 
                  and 
                  <a href="https://www.instagram.com/flyyourtech/" style="color: #913bfe; text-decoration: none;">Instagram</a>
                </p>
              </div>
            </div>
          </div>
        `
      };
  
      // Send both emails
      await Promise.all([
        sendEmail(ownerEmailData),
        sendEmail(userEmailData)
      ]);
      
      return true;
    } catch (error) {
      console.error('Error sending emails:', error);
      return false;
    }
  };
  
  // Update handleSubmit to redirect after submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateForm();
    
    if (Object.keys(newErrors).length === 0) {
      setLoading(true);
      
      try {
        const emailsSent = await sendEmails();
        
        if (emailsSent) {
          setSubmitted(true);
          setFormData({
            name: "",
            phone: "",
            email: "",
            message: ""
          });
          // Redirect after 5 seconds
          setTimeout(() => {
            window.location.href = '/';
          }, 5000);
        } else {
          setErrors({ submit: 'There was an error submitting the form. Please try again.' });
        }
      } catch (error) {
        setErrors({ submit: 'There was an error submitting the form. Please try again.' });
      } finally {
        setLoading(false);
      }
    } else {
      setErrors(newErrors);
    }
  };

  return (
    <>
    <Helmet>
        <title>Contact FlyYourTech (FYT) | Fly Your Tech | Your Technology Innovation Partner</title>
        <meta name="description" content="Connect with FlyYourTech (Fly Your Tech/FYT) for innovative digital solutions. We specialize in web development, mobile apps, blockchain, and AI/ML services. Transform your business with MP's leading tech partner." />
        <meta name="keywords" content="FlyYourTech, Fly Your Tech, FYT, fly your tech, flyyourtech, fyt tech, fly-your-tech, FYTech, F.Y.T, fly tech, contact FlyYourTech, tech company MP, software development, web development, app development, blockchain solutions, AI ML services, IT consulting, digital transformation, Indian tech company, technology partner, software company Madhya Pradesh, IT services India, custom development, enterprise solutions" />
        
        <meta property="og:site_name" content="FlyYourTech | Fly Your Tech | FYT" />
        <meta property="og:title" content="Contact FlyYourTech (Fly Your Tech) | FYT - Your Tech Innovation Partner" />
        <meta property="og:description" content="Transform your business with FlyYourTech (FYT). Expert web development, mobile apps, blockchain, and AI solutions from Madhya Pradesh's leading tech innovator." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://flyyourtech.com/contact" />
        
        <meta name="twitter:title" content="Contact FlyYourTech | Fly Your Tech (FYT) | Innovation Partner" />
        <meta name="twitter:description" content="Partner with FlyYourTech for cutting-edge tech solutions. MP's premier development team for web, mobile, blockchain, and AI services." />
        
        {/* Rich Snippets */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "FlyYourTech",
              "alternateName": [
                "Fly Your Tech",
                "FYT",
                "fly your tech",
                "flyyourtech",
                "FYTech",
                "F.Y.T",
                "fly-your-tech",
                "fly tech"
              ],
              "description": "Premier technology solutions provider specializing in web development, mobile apps, blockchain, and AI/ML solutions",
              "url": "https://flyyourtech.com",
              "logo": "https://flyyourtech.com/logo.png",
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+917470391011",
                "contactType": "customer service",
                "areaServed": ["IN", "Worldwide"],
                "availableLanguage": ["en", "hi"]
              },
              "sameAs": [
                "https://www.facebook.com/profile.php?id=61564327175573",
                "https://www.instagram.com/flyyourtech/"
              ],
              "address": {
                "@type": "PostalAddress",
                "addressRegion": "Madhya Pradesh",
                "addressCountry": "IN"
              },
              "foundingDate": "2022"
            }
          `}
        </script>
      </Helmet>

      <section className="w-full min-h-screen flex flex-col justify-between relative bg-[#05103d]">
        {/* Breadcrumb */}
        <div className="max-w-screen-xl pl-8 py-6 relative z-30">
          <div className="flex items-center gap-1">
            <Link to="/" className="text-white hover:text-[#913bfe]">
              Home
            </Link>
            <IoIosArrowForward className="text-white mt-1" />
            <span className="text-white">Contact</span>
          </div>
        </div>

        {/* Main Content */}
        <div className="max-w-screen-xl mx-auto w-full px-4 py-12 lg:py-20">
          <div className="grid lg:grid-cols-2 gap-12 items-start">
            {/* Left Column - Contact Info */}
            <div className="space-y-8">
              <div>
                <h1 className="text-5xl font-bold text-white mb-4">Contact Us</h1>
                {selectedType && (
                  <p className="text-xl font-bold text-white">
                    Selected Plan: {selectedType}
                  </p>
                )}
              </div>

              {/* Contact Information Cards */}
              <div className="grid md:grid-cols-2 gap-6">
                <div className="bg-[#0a1956] p-6 rounded-lg">
                  <div className="flex items-center gap-4 mb-4">
                    <IoTimeOutline size={30} className="text-[#913bfe]" />
                    <div>
                      <p className="text-white font-medium">Working Hours</p>
                      <p className="text-gray-300">10AM - 11:30PM</p>
                    </div>
                  </div>
                </div>

                <div className="bg-[#0a1956] p-6 rounded-lg">
                  <div className="flex items-center gap-4 mb-4">
                    <IoLocationOutline size={30} className="text-[#913bfe]" />
                    <div>
                      <p className="text-white font-medium">Location</p>
                      <p className="text-gray-300">(M.P), INDIA</p>
                    </div>
                  </div>
                </div>

                <div className="bg-[#0a1956] p-6 rounded-lg">
                  <a href="tel:+917470391011" className="flex items-center gap-4">
                    <MdOutlinePhone size={30} className="text-[#913bfe]" />
                    <div>
                      <p className="text-white font-medium">Phone</p>
                      <p className="text-gray-300">+917470391011</p>
                    </div>
                  </a>
                </div>

                {/* Social Links */}
                <div className="bg-[#0a1956] p-6 rounded-lg">
                  <div className="flex items-center gap-6">
                    <a
                      href="https://www.facebook.com/profile.php?id=61564327175573"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-[#913bfe] hover:text-white transition-colors"
                    >
                      <FaLinkedinIn size={30} />
                    </a>
                    <a
                      href="https://www.instagram.com/flyyourtech/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-[#913bfe] hover:text-white transition-colors"
                    >
                      <FaInstagram size={30} />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            {/* Right Column - Contact Form */}
            <div className="relative">
              <AnimatePresence>
                {submitted ? (
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    className="bg-[#0a1956] p-8 rounded-lg text-center"
                  >
                    <svg className="w-16 h-16 text-green-500 mx-auto mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <h3 className="text-2xl font-bold text-white mb-2">Thank You!</h3>
                    <p className="text-gray-300">We'll get back to you soon.</p>
                  </motion.div>
                ) : (
                  <motion.form
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    onSubmit={handleSubmit}
                    className="bg-[#0a1956] p-8 rounded-lg space-y-6"
                  >
                    <div className="grid md:grid-cols-2 gap-6">
                      <div>
                        <label className="block text-white text-sm font-medium mb-2">
                          Your Name
                        </label>
                        <input
                          type="text"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          className={`w-full px-4 py-3 rounded-lg bg-[#1a2156] text-white placeholder-gray-400 border ${
                            errors.name ? 'border-red-500' : 'border-[#2a3166]'
                          } focus:border-[#913bfe] focus:ring-1 focus:ring-[#913bfe] transition-colors`}
                          placeholder="John Doe"
                        />
                        {errors.name && (
                          <p className="mt-1 text-sm text-red-500">{errors.name}</p>
                        )}
                      </div>

                      <div>
                        <label className="block text-white text-sm font-medium mb-2">
                          Phone Number
                        </label>
                        <input
                          type="tel"
                          name="phone"
                          value={formData.phone}
                          onChange={handleChange}
                          className={`w-full px-4 py-3 rounded-lg bg-[#1a2156] text-white placeholder-gray-400 border ${
                            errors.phone ? 'border-red-500' : 'border-[#2a3166]'
                          } focus:border-[#913bfe] focus:ring-1 focus:ring-[#913bfe] transition-colors`}
                          placeholder="+91 XXXXXXXXXX"
                        />
                        {errors.phone && (
                          <p className="mt-1 text-sm text-red-500">{errors.phone}</p>
                        )}
                      </div>
                    </div>

                    <div>
                      <label className="block text-white text-sm font-medium mb-2">
                        Email Address
                      </label>
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className={`w-full px-4 py-3 rounded-lg bg-[#1a2156] text-white placeholder-gray-400 border ${
                          errors.email ? 'border-red-500' : 'border-[#2a3166]'
                        } focus:border-[#913bfe] focus:ring-1 focus:ring-[#913bfe] transition-colors`}
                        placeholder="john@example.com"
                      />
                      {errors.email && (
                   <p className="mt-1 text-sm text-red-500">{errors.email}</p>
                  )}
                </div>

                <div>
                  <label className="block text-white text-sm font-medium mb-2">
                    Your Message
                  </label>
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    rows="4"
                    className={`w-full px-4 py-3 rounded-lg bg-[#1a2156] text-white placeholder-gray-400 border ${
                      errors.message ? 'border-red-500' : 'border-[#2a3166]'
                    } focus:border-[#913bfe] focus:ring-1 focus:ring-[#913bfe] transition-colors`}
                    placeholder="Tell us about your project..."
                  />
                  {errors.message && (
                    <p className="mt-1 text-sm text-red-500">{errors.message}</p>
                  )}
                </div>

                {errors.submit && (
                  <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
                    {errors.submit}
                  </div>
                )}

                <button
                  type="submit"
                  disabled={loading}
                  className={`w-full bg-gradient-to-r from-[#913bfe] to-[#7b32d7] text-white font-semibold py-3 px-6 rounded-lg 
                    hover:from-[#7b32d7] hover:to-[#913bfe] transition-all duration-300 
                    transform hover:scale-[1.02] active:scale-[0.98] 
                    ${loading ? 'opacity-75 cursor-not-allowed' : ''}`}
                >
                  {loading ? (
                    <div className="flex items-center justify-center">
                      <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Sending...
                    </div>
                  ) : (
                    'Send Message'
                  )}
                </button>

                <p className="text-xs text-gray-400 text-center">
                  By submitting this form, you agree to our Terms of Service and Privacy Policy
                </p>
              </motion.form>
            )}
          </AnimatePresence>

          {/* Decorative Elements */}
          <motion.div
            className="absolute -right-8 -bottom-8 z-0 opacity-30"
            animate={{
              y: [0, 20, 0],
            }}
            transition={{
              duration: 5,
              repeat: Infinity,
              ease: "easeInOut",
            }}
          >
            <img
              src="https://techy-xi.vercel.app/assets/img/shape/line-round-1.svg"
              alt="decoration"
              className="w-24 h-24"
            />
          </motion.div>
        </div>
      </div>
    </div>

    {/* Background Decorative Elements */}
    <img
      src="https://techy-xi.vercel.app/assets/img/page-title/img-01.png"
      className="absolute bottom-0 left-0 z-0 opacity-30"
      alt="background decoration"
    />
    <img
      src="https://techy-xi.vercel.app/assets/img/shape/star-2.svg"
      className="absolute z-0 opacity-30 top-40 right-20"
      alt="star decoration"
    />
    <img
      src="https://techy-xi.vercel.app/assets/img/shape/star-5b.svg"
      className="absolute z-0 opacity-30 top-80 left-20"
      alt="star decoration"
    />
  </section>
</>
);
};

export default Contact;