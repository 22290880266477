// src/admin/Sidebar.jsx
import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { FiHome, FiUsers, FiSettings, FiLogOut, FiPlusCircle } from 'react-icons/fi';
import { useAuth } from './AuthContext';

const Sidebar = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/admin/login');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <div className="w-64 bg-white h-screen shadow-lg">
      <div className="p-6">
        <h1 className="text-2xl font-bold text-purple-900">FlyYourTech</h1>
      </div>

      <nav className="mt-6">
        <NavLink
          to="/admin/dashboard"
          className={({ isActive }) =>
            `flex items-center px-6 py-3 text-gray-700 ${
              isActive ? 'bg-purple-50 border-r-4 border-purple-500' : 'hover:bg-gray-50'
            }`
          }
        >
          <FiHome className="h-5 w-5" />
          <span className="mx-4">Dashboard</span>
        </NavLink>

        <NavLink
          to="/admin/leads"
          className={({ isActive }) =>
            `flex items-center px-6 py-3 text-gray-600 ${
              isActive ? 'bg-purple-50 border-r-4 border-purple-500' : 'hover:bg-gray-50'
            }`
          }
        >
          <FiUsers className="h-5 w-5" />
          <span className="mx-4">Leads</span>
        </NavLink>

        <NavLink
          to="/admin/leads/new"
          className={({ isActive }) =>
            `flex items-center px-6 py-3 text-gray-600 ${
              isActive ? 'bg-purple-50 border-r-4 border-purple-500' : 'hover:bg-gray-50'
            }`
          }
        >
          <FiPlusCircle className="h-5 w-5" />
          <span className="mx-4">Add Lead</span>
        </NavLink>

        <NavLink
          to="/admin/settings"
          className={({ isActive }) =>
            `flex items-center px-6 py-3 text-gray-600 ${
              isActive ? 'bg-purple-50 border-r-4 border-purple-500' : 'hover:bg-gray-50'
            }`
          }
        >
          <FiSettings className="h-5 w-5" />
          <span className="mx-4">Settings</span>
        </NavLink>
      </nav>

      <div className="absolute bottom-0 w-64 p-6">
        <button
          onClick={handleLogout}
          className="flex items-center px-4 py-2 text-gray-600 hover:text-gray-900"
        >
          <FiLogOut className="h-5 w-5" />
          <span className="mx-4">Logout</span>
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
