// OurWork.jsx
import React, { lazy, Suspense } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { FaInstagram, FaLinkedinIn } from "react-icons/fa6";
import { IoIosArrowForward } from "react-icons/io";
import useIntersectionObserver from "../hooks/useIntersectionObserver.jsx";

// Lazy load components
const ProjectGrid = lazy(() => import("../Components/ProjectGrid"));
const ContactNavbar = lazy(() => import("../Components/ContactNavbar"));

const OurWork = () => {
  const { ref: sectionRef, inView } = useIntersectionObserver({ threshold: 0.1 });

  const headingVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { duration: 0.9, ease: "easeOut" },
    },
  };

  return (
    <>
    <Helmet>
  <title>Portfolio | FlyYourTech (FYT) | Showcasing Digital Excellence</title>
  <meta name="description" content="Explore FlyYourTech's (Fly Your Tech/FYT) innovative portfolio featuring 200+ successful projects in web development, mobile apps, blockchain, and AI solutions. Discover how we transform businesses through technology." />
  <meta name="keywords" content="FlyYourTech portfolio, Fly Your Tech projects, FYT case studies, flyyourtech work, web development portfolio, mobile app showcase, blockchain projects, AI solutions, FYT success stories, tech portfolio MP, software projects India, digital transformation cases, React projects, Node.js portfolio, full-stack development, UI/UX showcase, enterprise solutions portfolio, startup projects, Indian tech portfolio, development case studies, IT success stories, custom software portfolio, technology innovation showcase" />
  
  {/* Open Graph Tags */}
  <meta property="og:site_name" content="FlyYourTech | Fly Your Tech | FYT" />
  <meta property="og:title" content="Portfolio | FlyYourTech (Fly Your Tech) | Digital Innovation Showcase" />
  <meta property="og:description" content="Discover FlyYourTech's portfolio of 200+ successful projects. From web apps to blockchain solutions, see how we drive digital transformation for businesses worldwide." />
  <meta property="og:type" content="website" />
  <meta property="og:url" content="https://flyyourtech.com/portfolio" />
  
  {/* Twitter Card Tags */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="FlyYourTech Portfolio | Innovation in Action" />
  <meta name="twitter:description" content="See how FlyYourTech transforms businesses through innovative tech solutions. 200+ projects showcasing excellence in web, mobile, and blockchain development." />
  
  {/* Additional Meta Tags */}
  <meta name="robots" content="index, follow" />
  <meta name="author" content="FlyYourTech" />
  <meta name="language" content="English" />
  <meta name="revisit-after" content="7 days" />
  <meta name="geo.region" content="IN-MP" />
  <meta name="geo.placename" content="Madhya Pradesh" />
  <meta name="geo.position" content="22.9734;78.6569" />
  <meta name="ICBM" content="22.9734, 78.6569" />
  
  {/* Rich Snippets */}
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "ProfilePage",
        "mainEntity": {
          "@type": "Organization",
          "name": "FlyYourTech",
          "alternateName": [
            "Fly Your Tech",
            "FYT",
            "fly your tech",
            "flyyourtech",
            "FYTech",
            "F.Y.T",
            "fly-your-tech",
            "fly tech"
          ],
          "description": "Leading technology solutions provider showcasing excellence in web development, mobile apps, blockchain, and AI/ML solutions",
          "url": "https://flyyourtech.com",
          "logo": "https://flyyourtech.com/logo.png",
          "sameAs": [
            "https://www.facebook.com/profile.php?id=61564327175573",
            "https://www.instagram.com/flyyourtech/"
          ],
          "address": {
            "@type": "PostalAddress",
            "addressRegion": "Madhya Pradesh",
            "addressCountry": "IN"
          },
          "areaServed": {
            "@type": "GeoCircle",
            "geoMidpoint": {
              "@type": "GeoCoordinates",
              "latitude": "22.9734",
              "longitude": "78.6569"
            }
          },
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "4.9",
            "reviewCount": "100",
            "bestRating": "5",
            "worstRating": "1"
          },
          "makesOffer": [
            {
              "@type": "Offer",
              "itemOffered": {
                "@type": "Service",
                "name": "Web Development",
                "description": "Custom web application development using React, Node.js, and modern technologies"
              }
            },
            {
              "@type": "Offer",
              "itemOffered": {
                "@type": "Service",
                "name": "Mobile App Development",
                "description": "Native and cross-platform mobile application development"
              }
            },
            {
              "@type": "Offer",
              "itemOffered": {
                "@type": "Service",
                "name": "Blockchain Solutions",
                "description": "Custom blockchain development and smart contract implementation"
              }
            },
            {
              "@type": "Offer",
              "itemOffered": {
                "@type": "Service",
                "name": "AI/ML Solutions",
                "description": "Artificial Intelligence and Machine Learning implementation for businesses"
              }
            }
          ]
        }
      }
    `}
  </script>
</Helmet>

      <section ref={sectionRef} className="bg-[#050c36] p-3">
        <Suspense fallback={<div className="h-16 bg-[#050c36]" />}>
          <ContactNavbar />
        </Suspense>

        <div className="text-white min-h-screen p-5 md:p-10">
          <div className="max-w-7xl mx-auto">
            <header className="mb-12">
              <motion.h1
                initial="hidden"
                animate={inView ? "visible" : "hidden"}
                variants={headingVariants}
                className="text-xl sm:text-3xl md:text-4xl font-bold text-center"
              >
                OUR PORTFOLIO
              </motion.h1>

              <p className="text-white text-justify md:text-start text-sm sm:text-md md:text-lg mt-4">
                At Fly Your Tech, we are committed to delivering exceptional
                services across web development, app development, and cutting-edge
                technology solutions.
              </p>
            </header>

            <nav className="flex mb-6 items-center gap-1">
              <Link to="/" className="text-white hover:text-[#913bfe]">
                Home
              </Link>
              <IoIosArrowForward className="text-white mt-1" />
              <span className="text-white">Our Portfolio</span>
            </nav>

            <Suspense fallback={<div className="h-96 bg-[#0c0d2c] animate-pulse rounded-lg" />}>
              <ProjectGrid />
            </Suspense>
          </div>
        </div>

        <footer className="max-w-screen-xl h-32 w-full mx-auto border-t border-[#242656] p-4 gap-5 flex-wrap flex items-center justify-center lg:justify-between">
          <span className="text-lg tracking-tight text-white dark:text-gray-300">
            <Link to="/">Fly Your Tech©2024</Link>, All Rights Reserved.
          </span>
          <div className="flex lg:mt-6 mb-3 sm:justify-center lg:space-x-5 space-x-10">
            <SocialLink href="https://www.instagram.com/flyyourtech/" icon={FaInstagram} label="Instagram" />
            <SocialLink href="https://www.linkedin.com/company/flyyourtech/" icon={FaLinkedinIn} label="LinkedIn" />
          </div>
        </footer>
      </section>
    </>
  );
};

// Extracted Social Link component
const SocialLink = ({ href, icon: Icon, label }) => (
  <a
    href={href}
    className="text-white p-3 hover:bg-[#913bfe] rounded-md border-[#1d254c] border-2 dark:hover:text-white"
  >
    <Icon className="cursor-pointer" size={22} />
    <span className="sr-only">{label} page</span>
  </a>
);

export default OurWork;





