// src/App.jsx
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';


// Public Pages
import Home from './Pages/Home.jsx';
import Contact from './Pages/Contact.jsx';
import OurWork from './Pages/OurWork.jsx';
import GetQuote from './Pages/GetQuote.jsx';
import FreeConsultation from './Pages/FreeConsultation.jsx';
import AboutUs from './Pages/AboutUs.jsx';
import PricingPage from './Pages/PricingPage.jsx';
import LeadPage from './Pages/LeadPage.jsx';

// Admin Components
import {
  Login,
  Dashboard,
  LeadsList,
  LeadModal,
  PrivateRoute,
  AdminLayout,
} from './admin/index.js';

// Components
import LeadForm from './Components/LeadForm.jsx';

import './index.css';



const App = () => (
  <Routes>
    {/* Public Routes */}
    <Route path="/" element={<Home />} />
    <Route path="/contact" element={<Contact />} />
    <Route path="/ourportfolio" element={<OurWork />} />
    <Route path="/get-quote" element={<GetQuote />} />
    <Route path="/free-consultation" element={<FreeConsultation />} />
    <Route path="/about-us" element={<AboutUs />} />
    <Route path="/our-packages" element={<PricingPage />} />
    <Route path="/lead-form" element={<LeadPage />} />

    {/* Admin Routes */}
    <Route path="/admin">
      <Route path="login" element={<Login />} />
      <Route element={<PrivateRoute />}>
        <Route element={<AdminLayout />}>
          <Route index element={<Navigate to="/admin/dashboard" replace />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="leads">
            <Route index element={<LeadsList />} />
            <Route path=":id" element={<LeadModal />} />
            <Route path="new" element={<LeadForm />} />
            <Route path="/admin/leads/new" element={<LeadForm />} />
            <Route path="/admin/leads/:id/edit" element={<LeadForm />} />
          </Route>
        </Route>
      </Route>
    </Route>

    {/* Catch all route */}
    <Route path="*" element={<Navigate to="/" replace />} />
  </Routes>
);

export default App;
